<template>
	<div>
		<iframead v-if="isPreviewingAd" />
		<v-app v-else :class="{ renderingAd: isRenderingAd }">
			<TheHeader />
			<v-main>
				<v-container>
					<router-view :key="$route.fullPath" />
				</v-container>
				<link
					href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap"
					rel="stylesheet"
				/>
			</v-main>
		</v-app>
	</div>
</template>

<script>
const TheHeader = () => import('@/components/Header.vue')
const iframead = () => import('@/views/iframead.vue')

export default {
	name: 'app',
	components: {
		TheHeader,
		iframead,
	},
	computed: {
		isRenderingAd() {
			return window.location.pathname.includes('/ad/')
		},
		isPreviewingAd() {
			return window.location.pathname.includes('/iframead')
		},
	},
	mounted() {
		document.title =
			window.location.hostname == 'lokal.norgeseliten.no' ? 'Lokal' : 'Skala'
	},
}
</script>

<style lang="less">
:root {
	--main-blue: #2f80ed;
	--notify-color: #b71c1c;
}
* {
	text-transform: none;
	box-sizing: border-box;
}
body {
	margin: 0;
}
.renderingAd {
	.v-application--wrap > .header {
		display: none;
	}
	.container {
		width: auto !important;
		max-width: none !important;
		padding: 0 !important;
	}
}
.ad-preview-iframe {
	border: none;
	overflow: hidden;
	background-color: #ffffff;
}
.before-after-away {
	&:before,
	&:after {
		display: none;
	}
}

#app {
	background: #f5f5f5;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Roboto', sans-serif;
	.v-btn {
		letter-spacing: normal;
	}
	h1 {
		font-weight: 400;
	}
	label {
		p {
			margin-top: 10px;
			margin-bottom: 5px;
		}
	}
	.v-card__text {
		font-size: 16px;
	}
	.v-text-field--outlined > .v-input__control > .v-input__slot {
		background: white;
	}
	.theme--light.v-card > .v-card__text,
	.theme--light.v-label {
		color: black;
	}
	.theme--light.v-divider {
		margin: 30px 0;
	}
	.v-menu__content .theme--light.v-divider {
		margin: 10px 0;
	}
	.v-tabs {
		flex: 0;
		width: auto;
	}
	.v-tab {
		padding: 0 9px;
	}
	.v-btn {
		min-width: 38px;
		padding: 0 9px;
	}
	.header .v-btn {
		height: 48px;
		border-radius: 0;
	}
	.container {
		max-width: 1185px;
	}
}
#app .brand-button {
	margin-right: 10px;
	width: 160px;
	height: auto;
	padding: 5px;
}
.octi-logo {
	width: 100px;
}
.brand-logo,
.brand-logo-single {
	position: relative;
	margin-right: 30px;
	width: 150px;
}
.brandSelect {
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	background: white;
	padding: 3px 0;
	border-radius: 2px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
	width: 230px;
	button {
		padding: 5px 10px;
		padding-left: 15px;
		display: block;
		width: 100%;
		text-align: left;
		&:before {
			content: '▶';
			opacity: 0;
			font-size: 8px;
			line-height: 0;
			position: relative;
			bottom: 2px;
			margin-right: 7px;
		}
		&.active {
			background-color: var(--main-blue);
			color: white;
			&:before {
				opacity: 1;
			}
		}
		&.addNewBrand {
			&:before {
				display: none;
			}
		}
	}
	hr {
		margin: 0 13px;
		border: none;
		border-top: 1px solid grey;
	}
}

.v-messages__wrapper:empty {
	display: none;
}

img {
	max-height: 100%;
	max-width: 100%;
}
h1 {
	font-weight: 400;
}
.brand-logo {
	width: 140px;
}
#nav {
	padding: 10px;
	display: flex;
	align-items: center;

	.v-tabs {
		display: inline-block;
		width: auto;
		flex-grow: initial;
	}
	a {
		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

.form-buttons {
	display: flex;
	display: inline-block;

	> label {
		margin-right: 10px;
		margin-bottom: 10px;
		&:last-child {
			margin-right: 0;
			margin-bottom: 0;
		}
	}
}

.align-right input {
	text-align: right;
}
label.inactive {
	opacity: 0.5;
	pointer-events: none;
	cursor: not-allowed;
	background-color: orange;
}
body .notify.notify-bottom-right {
	bottom: 0;
	right: 0;
	padding-bottom: 15px;
	padding-right: 15px;
}
.alert-notification {
	font-family: 'Roboto', sans-serif;
	background: white;
	padding: 12px 20px;
	border-radius: 5px;
	box-shadow: 0 7px 15px rgba(0, 0, 0, 0.3);
	border: 2px solid white;
	&.alert-info {
		background-color: #bbdefb;
	}
	&.alert-danger {
		background-color: #ffcdd2;
	}
	&.alert-warning {
		background-color: #ffcc80;
	}
	&.alert-success {
		background-color: #c8e6c9;
	}
}
div.notify.notify-bottom-right {
	z-index: 1000;
}
</style>
